//意大利
export const it = {
    //Barra di navigazione
    'n1': 'Casa',
    'n2': 'Ordine',
    'n3': 'Transazione',
    'n4': 'Squadra',
    'n5': 'mio',
    //classe prompt
    't1': 'Si è verificato un errore',
    't2': 'Copia riuscita',
    't3': 'Si prega di compilare le informazioni in modo completo',
    't4': 'Caricamento in corso...',
    't5': 'Accesso riuscito',
    't6': 'Il punteggio deve essere maggiore di 3 per essere inviato',
    't7': 'Invio riuscito',
    't8': 'Saldo insufficiente, impossibile ricevere',
    't9': 'Invio non riuscito',
    't10': "Per favore, prima collega l'indirizzo e verrai reindirizzato alla pagina del centro personale",
    't11': "L'indirizzo è stato associato, contatta il servizio clienti per modificarlo",
    't12': 'Modifica riuscita',
    't13': 'Modifica non riuscita',
    't14': 'Contatta il servizio clienti per modificare la password',
    't15': 'Errore nel formato della password',
    't16': 'Ritiro riuscito',
    't17': 'Prelievi massimi',
    't18': 'Prelievo minimo',
    't19': 'Due password non sono coerenti',
    't20': 'Richiesta',
    't21': 'Impossibile saltare temporaneamente',
    't22': 'Errore nel formato della password',
    't23': 'Registrazione riuscita',
    't24': 'Inserisci il prefisso',
    't25': 'Non più',
    //iscrizione
    'l1': 'Lingua',
    'l2': 'Inserisci il tuo numero di conto',
    'l3': 'Inserisci la password',
    'l4': 'ricorda',
    'l5': 'Accedi',
    'l6': 'Vai alla registrazione',
    'l7': 'Nessun account',
    'l8': 'Numero di cellulare',
    'l9': 'soprannome',
    'l10': 'password',
    'l11': 'Conferma password',
    'l12': 'codice invito',
    'l13': 'Registrati',
    'l14': 'Disponi già di un account',
    'l15': 'Vai al login',
    'l16': 'Inserisci una password di 6-16 cifre',

    //aiuto
    'bz1': 'Aiuto',
    'bz2': 'Domande frequenti',
    'bz3': 'Regole di trading',
    'bz4': 'Come prelevare denaro',
    'bz5': 'Come ricaricare',
    'bz6': "1.Cos'è ebay Review RoBoT?",
    'bz7': 'ebay Review RoBoT è una società di marketing e promozione che aiuta i commercianti dei negozi online di ebay Review RoBoT in tutto il mondo a ottenere più ordini venduti e ad aumentare i dati di navigazione dei negozi online di ebay Review RoBoT. Ci impegniamo a stabilire un modello tripartito di marketing e promozione redditizio tra ebay Review RoBoT, commercianti e consumatori. In combinazione con la più recente tecnologia blockchain P2P, consumatori e commercianti sono rapidamente connessi tramite USDT (TRC20, ERC20). Gli utenti registrati possono ottenere commissioni sugli ordini, mentre i commercianti possono aumentare i dati di vendita del proprio negozio. È l’ultimo modello di profitto nel modello blockchain di Internet! ',
    'bz8': '2.Come funziona ebay Review RoBoT?',
    'bz9': "Il feedback quotidiano di ebay Review RoBoT mostra che i prodotti che necessitano di miglioramenti vengono venduti in ebay Review RoBoT. Gli utenti devono solo fare facilmente clic sull'ordine e il sistema genera automaticamente un abbonamento all'ordine. Gli utenti pagano l'importo dell'ordine tramite blockchain USDT e ricevono commissioni giornaliere. ",
    'bz10': '3. Perché i prezzi della stessa valuta sono diversi in due transazioni? ',
    'bz11': "Le differenze di prezzo sono causate da tutti i fattori che non favoriscono la libera circolazione delle valute, inclusa la velocità dei trasferimenti di valuta, le condizioni della rete, le restrizioni sull'accesso alla valuta, il riconoscimento della valuta da parte di persone in diverse regioni e persino le coppie di scambio forniti da tipi di scambi, transazioni, ecc. Pertanto, la stessa valuta può presentare differenze di prezzo in transazioni diverse. ",
    'bz12': '4. Profitto degli investimenti? ',
    'bz13': "Più alto è il prezzo del prodotto, maggiore sarà il profitto dell'ordine. Allo stesso tempo, ebay Review RoBoT distribuisce in modo casuale ordini di commissioni di grandi dimensioni. ",
    'bz14': "Ogni giorno, il sistema genera e distribuisce automaticamente 60 ordini di prodotti agli utenti. Gli utenti completano il pagamento per ciascun ordine tramite USDT e ricevono una commissione dello 0.6%. Gli ordini con commissioni di grandi dimensioni vengono assegnati in modo casuale. ",
    'bz15': "L'USDT può essere ritirato dopo 60 ordini. (Se non vengono completati 60 ordini, il sistema si fermerà automaticamente fino al completamento degli ordini rimanenti della giornata)",
    'bz16': 'Ogni conto può essere associato a un solo conto USDT. Se si verifica un errore, contatta il servizio clienti',
    'bz17': "Dopo aver completato la consegna degli ordini giornalieri, puoi prelevare denaro normalmente. Arriverà entro 24 ore dall'inizio del prelievo e non esiste alcun limite di prelievo",
    'bz18': "Associa il tuo indirizzo USDT all'indirizzo di prelievo (supporta TRC-20) prima di ricaricare. Puoi fare clic su Ricarica sull'APP o sulla pagina web e selezionare la blockchain (TRC-20) che stai utilizzando per ricaricare. ",
  "bz19": "Ci sono comportamenti proibiti come la ricarica reciproca o i trasferimenti di banche e i trasferimenti di scambio tra conti correlati.",
    //testo del modulo
    //Parole comuni
    'ty1': 'OK',
    'ty2': 'Annulla',
    'ty3': 'Invia',
    'ty4': 'indirizzo',
    //prima pagina
    's1': 'Reddito cumulativo',
    's2': 'Servizio',
    's3': 'Chi siamo',
    's4': 'Aiuto',
    's5': 'Partner',
    's6': 'Il mio servizio',
    //Ordine
    'd1': 'Incompiuto',
    'd2': 'Completato',
    'd3': 'Ancora nessun dato',
    'd4': 'numero dispari',
    'd5': 'Tempo di negoziazione',
    'd6': 'importo',
    'd7': 'Numero attuale di attività',
    'd8': 'Rendimento previsto',
    'd9': 'Ne servono di più',
    'd10': 'Valutazione',
    //commercio
    'j1': 'Saldo del conto',
    'j2': 'Introduzione al trading',
    'j3': "ebay Review RoBoT utilizza ogni giorno il feedback di ebay Review RoBoT per mostrare i prodotti venduti da ebay Review RoBoT che necessitano di miglioramenti. Gli utenti devono solo fare clic sull'ordine e il sistema genererà automaticamente una firma dell'ordine. Gli utenti pagano l'importo dell'ordine tramite la blockchain USDT e possono ricevere ogni volta una commissione superiore allo 0,6% e il sistema distribuisce in modo casuale ordini di ricompensa di grandi dimensioni. ",
    'j4': 'Inizia la corrispondenza',
    'j5': 'Guadagni di oggi',
    'j6': 'Numero completato',
    'j7': 'Numero totale di attività',
    //squadra
    'td1': 'Saldo',
    'td2': 'commissione',
    'td3': 'Ricevi',
    'td4': 'Numero totale di persone',
    'td5': 'utente',
    'td6': 'contributo',
    'td7': 'Quantità',
    'td8': 'stato',
    //Centro personale
    'g1': 'Ritira',
    'g2': 'Ricarica',
    'g3': 'Invita amici',
    'g4': 'Invita ora',
    'g5': 'Per ogni profitto realizzato dal tuo amico dopo la registrazione, riceverai una percentuale corrispondente di commissione',
    "g6": "Informazioni personali",
    'g7': 'Dettagli del fondo',
    'g8': 'Ricarica record',
    'g9': 'Record di prelievo',
    'g10': 'Lingua',
    'g11': 'Esci',
    'g12': 'Sei sicuro di voler uscire?',
    //Informazioni utente
    'yh1': 'Informazioni utente',
    'yh2': 'Modifica',
    'yh3': 'impostazione',
    'yh4': 'telefono',
    'yh5': 'Sei sicuro di voler uscire?',
    'yh6': 'Password originale',
    'yh7': 'Nuova password',
    //Dettagli
    'mx1': 'Riscossione della commissione',
    'mx2': 'profitto',
    'mx3': 'Ritiro rifiutato',
    'mx4': 'ora',
    'mx5': 'Ricarica riuscita',
    'mx6': 'Rifiutato',
    'mx7': 'in sospeso',
    'mx8': 'Indirizzo di ritiro',
    'mx9': 'Saldo disponibile',
    'mx10': 'Arrivo effettivo',
    'mx11': "Inserisci l'importo del prelievo",

    //Riempire
    'cz1': "L'indirizzo di deposito supporta solo ERC20 e l'importo minimo del deposito è 20USDT",
    'cz2': 'Copia',
    'cz3': 'Selezione valuta Fiat',
    'cz4': 'pagamento',
    'cz5': "L'indirizzo di deposito supporta solo TRC20-USDT e l'importo minimo del deposito è 20USDT",
    'cz6': "L'indirizzo di deposito supporta solo ERC20 e l'importo minimo del deposito è 20USDT",
    'cz1': "L'indirizzo di deposito supporta solo ERC20 e l'importo minimo del deposito è 20USDT",
    'cz1': "L'indirizzo di deposito supporta solo ERC20 e l'importo minimo del deposito è 20USDT",
    //la zona
    'qy1': 'prefisso',
    'yq1': 'Invita amici',
    'yq2': 'codice invito',
    'yq3': 'Invita amici e goditi grandi vantaggi',
    'yq4': 'Vai e invita',
}
