import { React, useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import { Swiper, Toast, Dialog } from 'antd-mobile'
import { SoundOutline } from 'antd-mobile-icons'
import _ from 'lodash'
import './index.css'
import { useTranslation } from "react-i18next";
import { getInfo, getBanner, getNoRead, getService } from '../request/api'
import Tabber from '../copmonets/Tabber'
import axios from 'axios'
export default function Index() {
    const { t, i18n } = useTranslation();
    const throttle = _.throttle;
    let navigate = useNavigate();
    const [data, setData] = useState([])
    const [badge, setBadge] = useState('')
    const [props, setProps] = useState([])
    const [openids, setOpenids] = useState('')
    const [severUrls, setSeverUrls] = useState('')
    const getdata = async () => {
        const res = await getInfo({});
        setData(res.data)
        if (res.code == 0) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('warning');
            localStorage.removeItem('tan');
            localStorage.removeItem('tabber');
            Toast.show({
                icon: 'fail',
                content: res.info,
            })
            setTimeout(() => {
                window.location.hash = "/login"
            }, 2500);
        }
        if (res.data.openid !== '') {
            setOpenids(res.data.openid)
            setTimeout(() => {
                gettest(res.data.openid)
            }, 10);
        }
        if (res.data.tx_switch == 0 && !localStorage.getItem('warning')) {
            Dialog.alert({
                title: t('t20'),
                confirmText: t('ty1'),
                onConfirm: () => {
                    localStorage.setItem('warning', false)
                },
                content: (
                    <div className='react_pop'>
                        <div>{res.data.tx_info}</div>
                    </div>
                ),
            })
        }
        if (res.data.tan_info == '' || res.data.tan_info == null) {

        } else {
            if (localStorage.getItem('tan')) {
                return false
            }
            Dialog.alert({
                title: 'notice',
                confirmText: "ok",
                onConfirm: () => {
                    localStorage.setItem('tan', false)
                },
                content: (
                    <div className='react_pop'>
                        <div>{res.data.tan_info}</div>
                    </div>
                ),
            })
        }
    }
    const getUrl = async () => {
        await getService({}).then(res => {
            setSeverUrls(res.data)
            if (res.data == '') {
                Toast.show({
                    icon: 'fail',
                    content: t('t21'),
                })
            } else {
                Toast.show({
                    icon: 'loading',
                    content: t('t4'),
                })
            }
        })
    }
    const gettest = (val) => {
        let timer = setInterval(setIntervalService(val), 5000)
        localStorage.setItem('time', timer)
    }
    const setIntervalService = (val) => {
        if (val != '') {
            axios({
                url: "https://www.let-talks.cc/api/mobile/getNoRead",
                params: {
                    openid: val
                }
            }).then(res => {
                if (res.data.status == 200) {
                    setBadge(res.data.data.noRead)
                } else {
                    setBadge(0)
                }
            })
        }
    }
    const [loading, setLoading] = useState(false)
    const getData2 = async () => {
        const val = await getBanner({})
        setLoading(true)
        setProps(val.data)
        // const result = [];
        // for (let i = 0; i < 2; i++) {
        //     result.push(val.data.cooperative.slice(i * 6, (i + 1) * 6));
        // }
        // setPartnerData(result)
    }
    const firend = (url) => {
        if (url == '#') {
            Toast.show({
                icon: 'fail',
                content: t('t21'),
            })
        } else {
            window.open(url);
        }
    }
    useEffect(() => {
        getdata()
        getData2()
        getUrl()
    }, [])
    const ref = useRef(null)
    const banner = [{
        img: "/assets/index/banner1.jpg"
    },
    {
        img: "/assets/index/banner2.jpg"
    },
    {
        img: "/assets/index/banner3.png"
    }]
    const items = banner.map((item, index) => (
        <Swiper.Item key={index}>
            <div className='swiper_item'>
                <img src={item.img} alt="" key={index} />
            </div>
        </Swiper.Item>
    ))
    const severUrl = () => {
        window.open(severUrls)
    }
    useEffect(() => {
        return () => {
            clearInterval(localStorage.getItem('time'))
            localStorage.removeItem('time');
        }
    }, [])
    return (
        <div className='index_all'>
            <div className='header'>
                <img src="/assets/index/ebay2.svg" alt="" />
            </div>
            <div className="home_earnings">
                <p>{t('s1')}</p>
                <div className='user_money'>
                    <img src="/assets/index/usdt.svg"></img>
                    <p>{data.all_gec}</p>
                    <p>USDT</p>
                </div>
            </div>
            <div className='banner'>
                <Swiper indicatorProps={{
                    color: 'white',
                }} style={{
                    '--border-radius': '8px',
                }} loop autoplay>{items}</Swiper>
            </div>
            <div className='notice'>
                {props.notice && <Swiper autoplay indicator={() => null} direction='vertical' style={{ '--height': '36px' }}>
                    {
                        props.notice.map((item, index) => {
                            return (
                                <Swiper.Item key={index}>
                                    <div className='notice_swper'>
                                        <SoundOutline fontSize={18} />
                                        {item.content}
                                    </div>
                                </Swiper.Item>
                            )
                        })
                    }
                </Swiper>}
            </div>
            <div className='index_sever'>
                <h3>{t('s6')}</h3>
                <div className='sever_model'>
                    <div className='about_help'>
                        <div className='ah_model about' onClick={() => { navigate('/About') }}>
                            <p>{t('s3')}</p>
                        </div>
                        <div className='ah_model help' onClick={() => { navigate('/Help') }}>
                            <p>{t('s4')}</p>
                        </div>
                    </div>
                    <div className='sever' onClickCapture={severUrl}>
                        <p>{t('s2')}</p>
                        {badge != 0 && <p className='setBadge'>{badge > 100 ? '99+' : badge}</p>}
                    </div>

                </div>
            </div>
            <div className='teamwork'>
                <h3>{t('s5')}</h3>
                {loading && <div className='icon_banner'>
                    {
                        props.cooperative.map((item, index) => {
                            return (
                                <img src={item.img} alt="" key={index} onClick={() => { firend(item.url) }} />
                            )
                        })
                    }
                </div>}
            </div>
            <div style={{ height: '110px' }}></div>
            <Tabber></Tabber>
        </div>
    )
}
