//sk斯洛伐克
export const sk = {
    //Navigačná lišta
    'n1': 'Domov',
    'n2': 'Objednať',
    'n3': 'Transakcia',
    'n4': 'Tím',
    'n5': 'moja',
    //trieda výzvy
    't1': 'Vyskytla sa chyba',
    't2': 'Kopírovanie bolo úspešné',
    't3': 'Úplne vyplňte informácie',
    't4': 'Načítavam...',
    't5': 'Prihlásenie úspešné',
    't6': 'Na odoslanie musí byť skóre vyššie ako 3',
    't7': 'Odoslanie bolo úspešné',
    't8': 'Nedostatočný zostatok, nemožno prijať',
    't9': 'Odoslanie zlyhalo',
    't10': 'Najskôr zviažte adresu a budete presmerovaní na stránku osobného centra',
    't11': 'Adresa bola zviazaná, kontaktujte prosím zákaznícky servis a upravte ju',
    't12': 'Úprava úspešná',
    't13': 'Úprava zlyhala',
    't14': 'Pre zmenu hesla kontaktujte zákaznícky servis',
    't15': 'Chyba formátu hesla',
    't16': 'Výber bol úspešný',
    't17': 'Maximálne výbery',
    't18': 'Minimálny výber',
    't19': 'Dve heslá sú nekonzistentné',
    't20': 'Výzva',
    't21': 'Nie je možné dočasne preskočiť',
    't22': 'Chyba formátu hesla',
    't23': 'Registrácia úspešná',
    't24': 'Zadajte kód oblasti',
    't25': 'Už nie',
    //Prihlásiť Se
    'l1': 'Jazyk',
    'l2': 'Zadajte číslo svojho účtu',
    'l3': 'Zadajte heslo',
    'l4': 'pamätať',
    'l5': 'Prihlásenie',
    'l6': 'Prejsť na registráciu',
    'l7': 'Žiadny účet',
    'l8': 'Číslo mobilného telefónu',
    'l9': 'prezývka',
    'l10': 'heslo',
    'l11': 'Potvrdiť heslo',
    'l12': 'kód pozvánky',
    'l13': 'Registrovať',
    'l14': 'Už máte účet',
    'l15': 'Prejsť na prihlásenie',
    'l16': 'Zadajte 6- až 16-miestne heslo',

    //Pomoc
    'bz1': 'Pomoc',
    'bz2': 'FAQ',
    'bz3': 'Pravidlá obchodovania',
    'bz4': 'Ako vybrať peniaze',
    'bz5': 'Ako dobiť',
    'bz6': '1.Čo je ebay Review RoBoT?',
    'bz7': 'ebay Review RoBoT je marketingová a propagačná spoločnosť, ktorá pomáha obchodníkom s internetovými obchodmi ebay Review RoBoT na celom svete získať viac objednávok a zvýšiť údaje o prehliadaní internetového obchodu ebay Review RoBoT. Zaviazali sme sa vytvoriť tripartitný ziskový marketingový a propagačný model medzi ebay Review RoBoT, obchodníkmi a spotrebiteľmi. V kombinácii s najnovšou technológiou P2P blockchain sú spotrebitelia a obchodníci rýchlo prepojení prostredníctvom USDT (TRC20, ERC20). Registrovaní používatelia môžu získať provízie z objednávok, zatiaľ čo obchodníci môžu zvýšiť údaje o predaji svojho obchodu. Je to najnovší model zisku v internetovom blockchainovom modeli! ',
    'bz8': '2.Ako funguje ebay Review RoBoT?',
    'bz9': 'Denná spätná väzba od ebay Review RoBoT ukazuje, že produkty, ktoré si vyžadujú zlepšenie, sa predávajú v ebay Review RoBoT. Používatelia musia jednoducho kliknúť na objednávku a systém automaticky vygeneruje predplatné objednávky. Používatelia zaplatia sumu objednávky cez blockchain USDT a dostávajú denné provízie. ',
    'bz10': '3. Prečo sú ceny tej istej meny rozdielne v dvoch transakciách? ',
    'bz11': 'Cenové rozdiely sú spôsobené všetkými faktormi, ktoré neprispievajú k voľnému obehu mien, vrátane rýchlosti prevodu meny, podmienok siete, obmedzení prístupu k mene, rozpoznávania meny ľuďmi v rôznych regiónoch a dokonca aj obchodných párov poskytované typmi búrz, transakcií atď. Preto tá istá mena môže mať cenové rozdiely v rôznych transakciách. ',
    'bz12': '4. Investičný zisk? ',
    'bz13': 'Čím vyššiu cenu produktu získate, tým vyšší zisk z objednávky získate. Zároveň ebay Review RoBoT náhodne rozdeľuje veľké provízne objednávky. ',
    'bz14': 'Systém každý deň automaticky generuje a distribuuje používateľom 60 objednávok produktov. Používatelia zaplatia za každú objednávku prostredníctvom USDT a získajú províziu 0.6%. Veľké provízne objednávky sú prideľované náhodne. ',
    'bz15': 'USDT je ​​možné vybrať po 60 objednávkach. (Ak nie je dokončených 60 objednávok, systém sa automaticky zastaví, kým nezostanú zvyšné objednávky dňa)',
    'bz16': 'Každý účet môže byť viazaný iba na jeden USDT účet. Ak sa vyskytne chyba, kontaktujte zákaznícky servis',
    'bz17': 'Po dokončení doručovania denných objednávok si môžete peniaze normálne vybrať. Prídu do 24 hodín po začatí výberu a neexistuje žiadny limit na výber',
    'bz18': 'Pred dobitím zviažte svoju adresu výberu (podporuje TRC-20) USDT adresu. Môžete kliknúť na Dobiť v aplikácii alebo na webovej stránke a vybrať blockchain (TRC-20), ktorý používate na dobíjanie. ',
  "bz19": "Existujú zakázané správanie, ako je vzájomné dobíjanie alebo bankové prevody, a výmenné prevody medzi súvisiacimi účtami.",
    //text modulu
    //Bežné slová
    'ty1': 'OK',
    'ty2': 'Zrušiť',
    'ty3': 'Odoslať',
    'ty4': 'adresa',
    //predná strana
    's1': 'Kumulatívny príjem',
    's2': 'Služba',
    's3': 'O nás',
    's4': 'Pomoc',
    's5': 'Partner',
    's6': 'Moja služba',
    //Objednať
    'd1': 'Nedokončené',
    'd2': 'Dokončené',
    'd3': 'Zatiaľ žiadne údaje',
    'd4': 'nepárne číslo',
    'd5': 'Čas obchodovania',
    'd6': 'Suma',
    'd7': 'Aktuálny počet úloh',
    'd8': 'Očakávaná návratnosť',
    'd9': 'Potrebujem viac',
    'd10': 'Hodnotenie',
    //obchod
    'j1': 'Zostatok na účte',
    'j2': 'Obchodný úvod',
    'j3': 'ebay Review RoBoT používa každý deň spätnú väzbu od ebay Review RoBoT na zobrazenie produktov predávaných spoločnosťou ebay Review RoBoT, ktoré potrebujú zlepšenie. Používateľom stačí kliknúť na objednávku a systém automaticky vygeneruje podpis objednávky. Používatelia zaplatia sumu objednávky prostredníctvom blockchainu USDT a zakaždým môžu získať viac ako 0,6 % provízie a systém náhodne rozdeľuje veľké objednávky odmien. ',
    'j4': 'Začať priraďovať',
    'j5': 'Dnešné zárobky',
    'j6': 'Číslo dokončené',
    'j7': 'Celkový počet úloh',
    //tím
    'td1': 'Zostatok',
    'td2': 'provízia',
    'td3': 'Prijať',
    'td4': 'Celkový počet ľudí',
    'td5': 'používateľ',
    'td6': 'príspevok',
    'td7': 'Množstvo',
    'td8': 'stav',
    //Osobné centrum
    'g1': 'Vybrať',
    'g2': 'Recharge',
    'g3': 'Pozvať priateľov',
    'g4': 'Pozvať teraz',
    'g5': 'Za každý zisk, ktorý váš priateľ dosiahne po registrácii, dostanete zodpovedajúcu časť provízie',
    'g6': 'Osobné informácie',
    'g7': 'Podrobnosti o fonde',
    'g8': 'Recharge record',
    'g9': 'Záznam o odstúpení',
    'g10': 'Jazyk',
    'g11': 'Odhlásiť sa',
    'g12': 'Naozaj sa chcete odhlásiť?',
    //Informácie o používateľovi
    'yh1': 'Informácie o používateľovi',
    'yh2': 'Upraviť',
    'yh3': 'nastavenie',
    'yh4': 'telefón',
    'yh5': 'Naozaj sa chcete odhlásiť?',
    'yh6': 'Pôvodné heslo',
    'yh7': 'Nové heslo',
    //Podrobnosti
    'mx1': 'Výber provízií',
    'mx2': 'zisk',
    'mx3': 'Výber zamietnutý',
    'mx4': 'čas',
    'mx5': 'Dobitie úspešné',
    'mx6': 'Odmietnuté',
    'mx7': 'čaká sa',
    'mx8': 'Adresa na výber',
    'mx9': 'Dostupný zostatok',
    'mx10': 'Skutočný príchod',
    'mx11': 'Zadajte sumu výberu',
    //doplniť
    'cz1': 'Adresa vkladu podporuje iba ERC20 a minimálna výška vkladu je 20 USDT',
    'cz2': 'Kopírovať',
    'cz3': 'Výber meny Fiat',
    'cz4': 'platba',
    'cz5': 'Adresa vkladu podporuje iba TRC20-USDT a minimálna výška vkladu je 20 USDT',
    'cz6': 'Adresa vkladu podporuje iba ERC20 a minimálna výška vkladu je 20 USDT',
    'cz1': 'Adresa vkladu podporuje iba ERC20 a minimálna výška vkladu je 20 USDT',
    'cz1': 'Adresa vkladu podporuje iba ERC20 a minimálna výška vkladu je 20 USDT',
    //oblasť
    'qy1': 'kód oblasti',
    'yq1': 'Pozvať priateľov',
    'yq2': 'kód pozvánky',
    'yq3': 'Pozvite priateľov a užívajte si veľké výhody',
    'yq4': 'Choď a pozvi',
}
